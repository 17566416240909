<template>
  <div>
    <ColorSelectorSibiling :product="product" @siblingChange="$emit('siblingChange', $event)" />
    <div v-for="variant in variants" :key="variant.attribute_id">
      <div v-if="availableComponent[variant.attribute_code] && variant.values">
        <component
        :is="availableComponent[variant.attribute_code]"
        :product="product"
        :options="variant.values"
        :parentAttribute="variant.attribute_id"
        :filterActive="filterActive"
        :filterCode="variant.attribute_code"
        :oneSize="oneSize"
        :defaultSelected="hasSelectedOption(variant.attribute_id)"
        @filterSelected="filterChanged"
        @optionAvailable="optionAvailable" />
      </div>
      <div v-else>
        <checkbox-filter
        :options="variant.values"
        :parentAttribute="variant.attribute_id"
        :defaultSelected="hasSelectedOption(variant.attribute_id)"
        @filterSelected="filterChanged"/>
      </div>
    </div>
  </div>
</template>

<script>
// load component custom
import ColorSelector from './Variants/ColorSelector'
import ColorSelectorSibiling from './Variants/ColorSelectorSibiling'
import SizeSelector from './Variants/SizeSelector'
// default component
import CheckboxFilter from './Variants/CheckboxFilter'

export default {
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    variants: {
      type: Array,
      required: true
    },
    filterActive: {
      type: Object,
      required: true
    },
    defaultSelection: {
      type: Object,
      required: false
    },
    oneSize: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      componentToLoad: null
    }
  },
  computed: {
    availableComponent () {
      return {
        'color': ColorSelector,
        'size': SizeSelector
      }
    }
    // filterActive () {
    //   return this.$store.state.product.filterActive
    // }
  },
  methods: {
    hasSelectedOption (variantId) {
      if (this.defaultSelection && this.defaultSelection[variantId]) {
        return this.defaultSelection[variantId]
      } else {
        return null
      }
    },
    filterChanged (filterComposed) {
      this.$emit('changeFilter', filterComposed)
    },
    optionAvailable ($event) {
      return this.$emit('optionAvailable', $event)
    }
  },
  components: {
    CheckboxFilter,
    ColorSelectorSibiling
  }
}
</script>
