<template>
    <div class="color-list d-flex align-items-center">
      <div class="swatch-option" v-for="(option, index) in options" :key="index" :class="{active : option.value_index === colorSelectedIndex }">
        <div class="swatch-option__radio" :style="{backgroundColor: option.label}" @click.prevent="change(option)"></div>
      </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    parentAttribute: {
      type: String,
      required: true
    },
    defaultSelected: {
      type: String | Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      colorSelectedIndex: null
    }
  },
  mounted () {
    // set first color id
    if (this.defaultSelected) {
      let activeOption = _.find(this.options, ['value_index', this.defaultSelected])
      if (activeOption) {
        return this.change(activeOption)
      }
    }
    this.change(this.options[0])
  },
  methods: {
    change (newValues) {
      this.colorSelectedIndex = newValues.value_index
      this.$emit('filterSelected', this.getFilterCompose(newValues))
    },
    getFilterCompose (newValues) {
      return { type: this.parentAttribute, value: newValues }
    }
  }
}
</script>
