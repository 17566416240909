<template>
  <span>{{labelTranslated}}</span>
</template>

<script>

export default {
  props: {
    sizeAttr: {
      type: String | Number,
      required: true
    },
    fallback: {
      type: String,
      required: true
    }
  },
  computed: {
    getActiveCountry () {
      return this.$store.getters['ui/getActiveCountry']
    },
    labelTranslated () {
      const sizeTranslateMap = this.$store.getters['product/sizeTranslateMap']
      const sizeCountrySelected = this.getActiveCountry
      return sizeTranslateMap[this.sizeAttr] ? sizeTranslateMap[this.sizeAttr][sizeCountrySelected] : this.fallback
    }
  }
}
</script>
