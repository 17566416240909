<template>
  <!-- image file -->
  <img
    v-if="isImage"
    class="img-fluid"
    v-lazy="imgObj"
    :alt="alt"
    :title="alt"
    :key="imgObj.src"/>
  <!-- other file -->
  <iframe
  v-else
  :src="src"
  frameborder="0"
  allow="autoplay; fullscreen"
  allowfullscreen></iframe>
</template>

<script>
export default {
  name: 'ImageOrIframe',
  props: {
    src: {
      type: String,
      default: ''
    },
    imageBaseUrl: {
      type: String,
      required: false,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    defaultLoading: {
      default: () => require('@/assets/image/dump-square.png')
    }
  },
  computed: {
    isImage () {
      return this.src && !!this.src.match(/(.png)|(.jpg)|(.jpeg)|(.svg)$/i)
    },
    imgObj () {
      return {
        src: (this.imageBaseUrl) ? `${this.imageBaseUrl}/${this.src}` : this.src,
        error: this.defaultLoading,
        loading: this.defaultLoading
      }
    }
  }
}
</script>
