<template>
  <!-- Card product require
      - image
      - name
      -url
      -price
      -custom attribute (Exclusive online)
    -->
  <div class="block-product card-product-teaser">
    <div class="card-product">
      <div class="card-product__media">
        <span class="kit-of" v-show="productSet">{{ $t('kit-of-{count}', { count: productSet })}}</span>
        <router-link
          :to="productLink"
          :title="product.name">
          <image-gallery
          :imgPath="getCropUrl"
          :alt="product.name"
          :styleImage="{ maxWidth: '100%', height: 'auto' }"
          :changeAble="false"/>
        </router-link>
      </div>
      <div class="card-product__info">
        <div class="card-product__info__intro">
          <router-link
          :to="productLink"
          :title="product.name"
          class="card-product__info__intro__name">
            <h2 class="name-title" v-html="product.name"></h2>
          </router-link>
        </div>
        <div class="card-product__info__intro__price">
          <product-price :product="product"></product-price>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageGallery from '@/components/Product/Image.vue'
import ProductPrice from '@/components/Listing/ProductPrice.vue'
import Config from '@/config'
import { localizedByName } from '@/filters/routers'

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    productSet () {
      return this.product.set
    },
    productLink () {
      if (this.product) {
        let path = '/'
        // to do testare la categoria
        /* if (this.$store.state.category.current && this.$store.state.category.current.url_path) {
          path += this.$store.state.category.current.url_path + '/'
        } */
        return localizedByName('factoryPage', this.$store, this.$router) + path + this.product.url_key + this.product.url_suffix
      }
      return ''
    },
    tileMedia () {
      // get Listing type in media_gallery_entries
      let media = this.product.media_gallery[0].url || this.product.image.url
      let entries = this.product.media_gallery_entries || []
      if (entries.length) {
        let listingMedia = entries.find((entry) => entry.types.includes('listing'))
        if (listingMedia) {
          media = `${this.$store.getters['storeConfig/productPath']}${listingMedia.file}`
        }
      }
      return media || ''
    },
    getCropUrl () {
      const { width, height } = Config.Theme.productOpt.imageCrop
      return `${this.tileMedia}?fit=crop&w=${width}&h=${height}`
    }
  },
  components: {
    ImageGallery,
    ProductPrice
  }
}
</script>
