<template>
  <div class="product-info__color">
    <div class="color-title">
      <strong><span class="text-uppercase">{{$t('color')}}</span> {{colorSelectedLabel}}</strong>
    </div>
    <color-swatch :options="options" :parentAttribute="parentAttribute" :defaultSelected="defaultSelected" @filterSelected="change($event)"></color-swatch>
  </div>
</template>

<script>
import ColorSwatch from './ColorSwatch/ColorSwatch'

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    parentAttribute: {
      type: String,
      required: true
    },
    defaultSelected: {
      type: String | Number,
      required: false,
      default: null
    }
  },
  components: {
    ColorSwatch
  },
  data () {
    return {
      colorSelectedLabel: null
    }
  },
  methods: {
    change (newValues) {
      this.colorSelectedLabel = newValues.value.label
      this.$emit('filterSelected', this.getFilterCompose(newValues.value.value_index))
    },
    getFilterCompose (newValues) {
      return { type: this.parentAttribute, value: newValues }
    }
  }
}
</script>
