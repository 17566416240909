<template>
  <button type="button" class="btn btn-none ff-link ff-link-icon btn-whishlist cta-wishlist" :class="{'active': isOnWishlist }" @click.prevent="actionOnWishlist">
    <i class="ff-icon ff-icon-17-wishlist"></i>
  </button>
</template>

<script>
import AddToWishlist from '@/components/AddToWishlist'

export default {
  mixins: [AddToWishlist],
  name: 'AddGiftcardToWishlist',
  methods: {
    addToWishlist () {
      return this.$emit('addToWishlist')
    }
  }
}
</script>
