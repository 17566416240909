<template>
  <div class="wrapp-stores-list">
    <div class="wrapp-stores-list__content" v-if="storesByProduct">
      <div class="wrapp-stores-list__content__list" v-if="storesByProduct && storesByProduct.stores_available.length > 0">
        <template v-for="store in storesByProduct.stores_available">
          <card-store :key="store.code" :store="store"></card-store>
        </template>
      </div>
      <!-- <div class="wrapp-stores-list__content__nolist mt-4" v-else>
        <h4 class="mx-auto">{{ $t('no-boutique-found') }}</h4>
      </div> -->
    </div>
  </div>
</template>

<script>
import CardStore from './CardStore'
export default {
  name: 'StoresList',
  computed: {
    storesByProduct () {
      return this.$store.getters['storeLocator/getStoresByProduct']
    }
  },
  destroyed () {
    this.$store.commit('storeLocator/setStoresByProduct', null)
  },
  components: {
    CardStore
  }
}
</script>
