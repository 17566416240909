<template>
    <span v-html="extraInfo"></span>
</template>

<script>
export default {
  props: {
    onlyOneLeft: {
      type: Boolean,
      required: false
    },
    outOfStock: {
      type: Boolean,
      required: false
    },
    isPreOrder: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    extraInfo () {
      if (this.outOfStock) {
        return '<span class="classic-action"><u>' + this.$t('notify-me') + '</u></span>'
      } else if (this.isPreOrder) {
        return '<span class="classic-action"><u>' + this.$t('preorder') + '</u></span>'
      } else if (this.onlyOneLeft) {
        return '<span class="last-left">' + this.$t('last-on-left') + '</span>'
      } else {
        return ''
      }
    }
  }
}
</script>
