import { render, staticRenderFns } from "./GiftCardProduct.vue?vue&type=template&id=f7437c3a&"
import script from "./GiftCardProduct.vue?vue&type=script&lang=js&"
export * from "./GiftCardProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "GiftCardProduct.vue"
export default component.exports