<template>
  <div class="product-info__sizes">
    <div class="d-flex align-items-center justify-content-between wrapp-info-row">
      <div class="country-select" v-show="!oneSize">
        <b-nav class="d-none d-lg-flex">
          <b-nav-item v-for="sizeCountry in sizeCountries" :key="sizeCountry.value" @click="setSizeCountry(sizeCountry)" :active="sizeCountry.selected">
            {{sizeCountry.value}}
          </b-nav-item>
        </b-nav>
        <div class="d-block d-lg-none">
          <div class="country-size-label">
            <div class="text">
              <span>{{ $t('country-size')}}:</span>
            </div>
            <no-ssr>
              <v-select
              class="size-country-select"
              :options="sizeCountries"
              label="value"
              :clearable="false"
              :searchable="false"
              :value="getActiveCountry"
              @input="setSizeCountry($event)">
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <span class="arrow-down-end"></span>
                </span>
              </template>
              </v-select>
            </no-ssr>
          </div>
        </div>
      </div>
      <div class="size-guide ml-auto" v-show="!fitSuggestion && sizeGuide">
        <a href="#" class="bcm-link bcm-link-secondary" @click.prevent="toggleSizeGuide({ open: true, activeTab: 0})">{{ $t('size-guide') }}</a>
      </div>
    </div>
    <div class="wrapp-info-row fit-suggestion-row" v-show="fitSuggestion && !oneSize">
      <div class="wrapp-titles">
        <div class="fit-guide">
          <button v-b-toggle="'fit-suggestion'" class="btn btn-none bcm-link bcm-link-secondary">{{ $t('fit-guide') }}</button>
        </div>
        <div class="separator mx-2" v-show="sizeGuide">|</div>
        <div class="size-guide" v-show="sizeGuide">
          <a href="#" class="bcm-link bcm-link-secondary" @click.prevent="toggleSizeGuide({ open: true, activeTab: 0})">{{ $t('size-guide') }}</a>
        </div>
      </div>
      <b-collapse id="fit-suggestion">
        <div class="fit-suggestion-collapse">
          <div class="wrapp-block">
            <p>{{fitSuggestion}}</p>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="wrapp-size-select" v-show="!oneSize">
      <b-spinner label="Spinning" v-if="loadVariants"></b-spinner>
      <no-ssr>
        <v-select
        v-if="!loadVariants"
        ref="sizeSelect"
        class="size-select"
        :class="{'has-errors': sizeError}"
        v-model="selected"
        label="label"
        :options="optionsByFilter"
        :clearable="false"
        :searchable="false"
        :selectable="selectableOption"
        @input="change($event)"
        @open="toggleSizeSelect(true)"
        @close="toggleSizeSelect(false)"
        :placeholder="$t('Select size')">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <span class="arrow-down-end"></span>
            </span>
          </template>
          <template slot="selected-option" slot-scope="opt">
            <div class="wrapp-option" v-if="opt.value">
              <SizeSelectorTranslate class="option-label" :sizeAttr="opt.value" :fallback="opt.label || ''"/>
              <size-selector-extra-label
              class="extra-label"
              :onlyOneLeft="opt.quantity === 1"
              :outOfStock="opt.quantity <= 0"
              :isPreOrder="opt.is_preorder"
              ></size-selector-extra-label>
            </div>
            <span v-else class="option-label">{{ $t('Select size') }}</span>
          </template>
          <template #option="opt" :deselect="true">
            <div class="wrapp-option" :class="{'out-of-stock': opt.quantity <= 0}">
              <SizeSelectorTranslate class="option-label" :sizeAttr="opt.value" :fallback="opt.label"/>
              <size-selector-extra-label
              class="extra-label"
              :onlyOneLeft="opt.quantity === 1"
              :outOfStock="opt.quantity <= 0"
              :isPreOrder="opt.is_preorder"
              ></size-selector-extra-label>
            </div>
          </template>
        </v-select>
      </no-ssr>
      <p class="errors-message is-invalid text-right mt-2" v-t="'size-select-error'" v-show="sizeError"></p>
    </div>
    <ModalSizeGuide v-model="modalSizeGuide.open" :activeTab="modalSizeGuide.activeTab" v-on:close="toggleSizeGuide({open: false, activeTab: 0})" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/helpers'
import Config from '@/config'
import ModalSizeGuide from '../../Modal/SizeGuide'
import SizeSelectorExtraLabel from './SizeSelectorExtraLabel'
import SizeSelectorTranslate from './SizeSelectorTranslate'

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    product: {
      type: Object,
      default: () => ({})
    },
    parentAttribute: {
      type: String,
      required: true
    },
    filterActive: {
      type: Object,
      required: true
    },
    defaultSelected: {
      type: Number,
      required: false,
      default: () => null
    },
    filterCode: {
      type: String,
      default: () => 'size'
    },
    oneSize: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      sizeError: false,
      hoverChoice: null,
      selected: null,
      modalSizeGuide: {
        open: false,
        activeTab: 0
      }
    }
  },
  computed: {
    ...mapState({
      loadVariants: state => state.product.loadConfigurable
    }),
    getActiveCountry () {
      return this.$store.getters['ui/getActiveCountry']
    },
    sizeCountries () {
      let sizeCountries = Config.Theme.sizeCountries
      return sizeCountries.map(item => ({
        value: item.value,
        selected: item.value === this.getActiveCountry
      }))
    },
    fittingLabel () {
      const attributesLabel = this.$store.state.product.current.attributesLabel
      return attributesLabel && attributesLabel.fitting_filter ? attributesLabel.fitting_filter.value : ''
    },
    optionsByFilter () {
      let filters = this.$store.getters['product/getVariantByFilter'](this.filterCode, this.filterActive)
      this.$emit('optionAvailable', filters.length)
      return filters
    },
    selectableOutOfStock () {
      return this.$store.state.product.selSizeProductOutOfStock
    },
    fitSuggestion () {
      if (this.product && this.product.aqa_fit_suggestion) {
        return this.product.aqa_fit_suggestion
      } else {
        return false
      }
    },
    sizeGuide () {
      if (this.product && this.product.attributesLabel.aqa_product_size_guide) {
        let sizeGuide = this.product.attributesLabel.aqa_product_size_guide
        if (sizeGuide.value === 'no-size-guide') {
          return false
        }
        return this.product.attributesLabel.aqa_product_size_guide
      } else {
        return false
      }
    }
  },
  methods: {
    resetFilterSize () {
      this.selected = null
      return this.change({ value: this.selected })
    },
    toggleSizeSelect ($event) {
      EventBus.$emit('sizeSelector:toggled', $event)
    },
    selectableOption (option) {
      if (!this.selectableOutOfStock) {
        return (option.quantity <= 0)
      }
      return true
    },
    calcClassName (opt) {
      let className = {
        'active': this.selected && this.selected.value === opt.value,
        'out-of-stock': opt.quantity <= 0,
        'unselectableOptions': !this.selectableOption(opt)
      }
      return className
    },
    showError (payload) {
      this.sizeError = payload
    },
    listenerEventChange (payload) {
      this.$nextTick(function () {
        // try to find on new option with old value selected
        if (this.selected && this.selected.value) {
          let matchArr = this.optionsByFilter.find(el => {
            return el.value === this.selected.value
          })
          this.selected = matchArr
          if (matchArr) {
            this.change(matchArr)
          }
        } else {
          return this.change({ value: '' })
        }
      })
    },
    mouseover (optID) {
      this.hoverChoice = optID
    },
    mouseleave () {
      this.hoverChoice = null
    },
    change (newValues) {
      this.selected = newValues
      this.showError(false)
      this.$emit('filterSelected', this.getFilterCompose(newValues))
    },
    getFilterCompose (newValues) {
      return { type: this.parentAttribute, value: newValues.value, isNotificable: newValues.quantity <= 0 }
    },
    toggleSizeGuide (data) {
      this.modalSizeGuide = {
        open: data.open,
        activeTab: data.activeTab
      }
    },
    setSizeCountry (sizeCountry) {
      this.$store.commit('ui/sizeCountrySelected', sizeCountry.value)
    },
    activeByDefault () {
      if (this.defaultSelected) {
        if (this.optionsByFilter.length) {
          this.selected = this.optionsByFilter.find(el => {
            return el.value === this.defaultSelected
          })
          return this.change(this.selected)
        } else {
          this.selected = { value: this.defaultSelected }
          return this.listenerEventChange({ type: this.filterCode })
        }
      } else {
        return this.listenerEventChange({ type: this.filterCode })
      }
    }
  },
  watch: {
    defaultSelected (newVal, old) {
      if (newVal !== old) {
        this.activeByDefault()
      }
    }
  },
  mounted () {
    EventBus.$on('quickBuy:resetFilterSize', this.resetFilterSize)
    EventBus.$on('cart:sizeError', this.showError)
    this.activeByDefault()
  },
  destroyed () {
    EventBus.$off('quickBuy:resetFilterSize', this.resetFilterSize)
    EventBus.$off('cart:sizeError', this.showError)
  },
  components: {
    ModalSizeGuide,
    SizeSelectorExtraLabel,
    SizeSelectorTranslate
  }
}
</script>
