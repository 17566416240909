<template>
  <transition name="fadeIn">
    <div class="media-zoomer zoomer-container" ref="zoomerContainer" @mousemove="overPosition" @touchmove="overPosition($event, useTouch = true)">
      <div class="current-image">
        <image-gallery
        :imgPath="activeImage"
        :hasVideo="(images[imageIndex] && images[imageIndex].video_content) ? images[imageIndex].video_content.video_url : ''"
        :has3dImage="(images[imageIndex] && images[imageIndex].code3D) ? images[imageIndex].code3D : ''"
        :styleImage="{ transform: `translate(${userPosition})`}"
        imageClass="current-image__src"
        :changeAble="true" />
        <!-- <img
        :src="activeImage"
        :style="{ transform: `translate(${userPosition})`}"
        draggable="false"
        class="current-image__src" /> -->
      </div>
      <div class="action-container">
        <no-ssr>
          <overlay-scrollbars
          class="thumb-list"
          ref="thumbScrollbar"
          :options="{ paddingAbsolute: false, scrollbars: { visibility: 'h' } } ">
          <ul class="nav flex-md-column">
            <li v-for="(image, index) in images" :key="index" :class="{'active-index': (imageIndex === index) }" @click.prevent="inspectPhoto(index)">
              <image-gallery
              :imgPath="image.url"
              :hasVideo="(image && image.video_content) ? image.video_content.video_url : ''"
              imageClass="thumb-image"
              :changeAble="true" />
              <!-- <img :src="image.url" class="thumb-image" @click.prevent="inspectPhoto(index)" /> -->
            </li>
          </ul>
          </overlay-scrollbars>
        </no-ssr>
      </div>
      <div class="zoomer-close">
        <button type="button" class="btn btn-icon btn-close" @click.prevent="$emit('close-zoomer', true)">
          <i class="aqz-icon aqz-icon-04-close cl-black"></i>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'
import ImageGallery from '@/components/Product/Image.vue'

export default {
  name: 'Zoomer',
  props: {
    images: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    activeindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      imageIndex: this.activeindex,
      activeImage: '',
      userPosition: null
    }
  },
  computed: {
    imgObj () {
      return {
        src: this.activeImage,
        error: require('@/assets/image/no-image.png'),
        loading: (this.size === 'LG' ? require('@/assets/image/dump.png') : require('@/assets/image/dump-square.png'))
      }
    }
  },
  methods: {
    inspectPhoto (index) {
      this.imageIndex = index
      this.activeImage = this.images[index].url
      return this.activeImage
    },
    overPosition: _.throttle(function (e, useTouch = false) {
      let windowData = {
        width: $(window).width(),
        height: $(window).height(),
        halfWidth: $(window).width() / 2,
        halfHeight: $(window).height() / 2,
        imageElWidth: $('.current-image__src').width(),
        imageElHeight: $('.current-image__src').height()
      }
      let el = $('.current-image')[0]
      const y = this.getOffset(el)
      // get coordinates Y - X from mobile or desktop
      let thumbHeight = getComputedStyle(document.documentElement).getPropertyValue('--thumb-list-height').replace('px', '')
      let pageY = (useTouch) ? e.changedTouches[0].pageY : e.pageY
      let pageX = (useTouch) ? e.changedTouches[0].pageX : e.pageX
      let centerPosImageH = (windowData.imageElHeight - (windowData.height - thumbHeight)) / 2
      let centerPosImageW = (windowData.imageElWidth - windowData.width) / 2
      let moveImageY = (-centerPosImageH * (pageY - y.top)) / windowData.halfHeight
      let moveImageX = 0
      if (windowData.imageElWidth > windowData.width) {
        // in mobile calc X
        moveImageX = (-centerPosImageW * (pageX - y.top)) / (windowData.width / 1.5)
      }
      this.userPosition = `${parseInt(moveImageX)}px, ${parseInt(moveImageY)}px`
      return this.userPosition
    }, 200),
    getOffset (el) {
      let rect = el.getBoundingClientRect()
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
  },
  mounted () {
    document.querySelector('html').classList.add('no-overflow')
    this.inspectPhoto(this.activeindex)
  },
  destroyed () {
    document.querySelector('html').classList.remove('no-overflow')
  },
  components: {
    ImageGallery
  }
}
</script>
