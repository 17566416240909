<template>
  <b-container>
    <breadcrumb
      :items="pathBreadcrumb"
    />
    <b-row>
      <b-col>
        <h1 class="text-left" v-html="product.name"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center" md="5">
        <image-gallery
          :imgPath="selectedImage"
          size="MD"
          :styleImage="{ maxHeight: '300px' }"
          :changeAble="true"/>
        <carousel v-if="this.product.media_gallery"
          :img-gallery="this.product.media_gallery"
          @clickSlide="updateImage"
        />
      </b-col>
      <b-col class="text-left" md="7">
        <div class="mt-4 mb-4" v-html="product.description.html"></div>
        <strong>sku: {{product.sku}}</strong>
        <strong>: {{product.stock_status}}</strong>
        <!-- Configurable options -->
        <configurable-options v-if="this.product.configurable_options"
        :product-options="this.product.configurable_options" />
        <div class="price">
          <div class="box-qty">
            <label class="mr-4" >{{ $t('Quantity')}}</label>
            <span @click="minusQuantity" class="minus pointer"><i class="material-icons">remove</i></span>
            <input type="number" class="text-center" title="Qty" v-model="quantityProduct" name="qty" id="qty"/>
            <span @click="plusQuantity" class="plus pointer"><i class="material-icons">add</i></span>
          </div>
          <strong> {{ product.price_range.maximum_price.final_price.value | price($store) }}</strong>
        </div>
        <add-to-cart
          :sku="product.sku"
          :qty="quantityProduct"
        />
      </b-col>
    </b-row>
    <b-row class="my-5">
      <b-col>
        <section-tabs :info-product="tabsDesc"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import Vue from 'vue'
import SectionTabs from '@/components/Tabs'
import ImageGallery from '@/components/Product/Image.vue'
import Carousel from '@/components/Carousel.vue'
import ConfigurableOptions from '@/components/Product/ConfigurableOptions.vue'
import AddToCart from '@/components/AddToCart'
import { getCategoriesFromPath } from '@/helpers/utils'
// import { getBreadcrumbFromCategoryObj } from '@/helpers/utils'
import Config from '@/config'

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      quantityProduct: 1,
      updateImageProduct: undefined
    }
  },
  computed: {
    /* getCategoryPath () {
      return getBreadcrumbFromCategoryObj(this.product.categories, this.$store)
    }, */
    pathBreadcrumb () {
      const _this = this
      let paths = [{
        text: Config.Theme.name,
        to: Vue.filter('localizedByNameCategories')('home', null, null, this.$store, this.$router),
        exact: true
      }]
      if (this.product.categories) {
        if (this.$route.params.slug) {
          // from category from url
          const categoryUrlPath = this.$route.params.slug.substr(0, this.$route.params.slug.lastIndexOf('/'))
          const objCategories = getCategoriesFromPath(categoryUrlPath, this.$store.state.category.flatted)
          if (objCategories.length) {
            paths = paths.concat(objCategories.map(item => {
              return {
                text: item.name,
                to: Vue.filter('localizedByNameCategories')('listing', item.url_path, null, _this.$store, _this.$router),
                active: false
              }
            }))
          }
          // product from category
          /* const objCategories = this.getCategoryPath
          if (objCategories.length) {
            console.log('error')
            paths = paths.concat(objCategories.map(item => {
              return {
                text: item.category_name,
                to: Vue.filter('localizedByNameCategories')('listing', item.category_url_path, null, _this.$store, _this.$router),
                active: false
              }
            }))
          } */
        }
      }
      // add current product
      paths.push({
        text: this.product.name,
        active: true
      })
      if (this.$store.state.listing.pagination.page !== 1) {
        let backPagination = this.$store.state.listing.pagination
        paths[paths.length - 2].to = `${paths[paths.length - 2].to}?size=${backPagination.size}&start=${backPagination.start}&page=${backPagination.page}`
      }
      return paths
    },
    moreInfo () {
      // list of more info
      // material
      // sleeve
      // size
      // collar
      // pattern
      // climate
      return []
    },
    tabsDesc () {
      return [
        {
          title: 'description',
          value: this.product.description ? this.product.description.html : ''
        },
        {
          title: 'more info',
          value: ''
        },
        {
          title: 'lorem',
          value: 'Lorem ipsum dolor sit amet, consectetur adipisci elit...'
        }
      ]
    },
    selectedImage () {
      return this.updateImageProduct || (this.product.image ? this.product.image.url : '')
    }
  },
  methods: {
    minusQuantity () {
      if (this.quantityProduct > 1) {
        return --this.quantityProduct
      }
      return this.quantityProduct
    },
    plusQuantity () {
      return ++this.quantityProduct
    },
    // addToCart (sku) {
    //   alert('Sku: ' + sku + ' Quantità: ' + this.quantityProduct)
    // },
    updateImage (newPathImg) {
      this.updateImageProduct = newPathImg
    }
  },
  components: {
    Breadcrumb,
    SectionTabs,
    ImageGallery,
    Carousel,
    ConfigurableOptions,
    AddToCart
  }
}
</script>
