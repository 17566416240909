<template>
  <!-- image file -->
  <video
  class="video-or-iframe__video"
  playsinline
  :controls="controls"
  :autoplay="autoplay"
  :loop="loop"
  v-if="isVideo">
    <source
    :src="src"
    type="video/mp4">
    Your browser does not support HTML5 video.
  </video>
  <!-- other file -->
  <iframe
  class="video-or-iframe__iframe"
  v-else
  :src="src"
  frameborder="0"
  allow="autoplay; fullscreen"
  allowfullscreen></iframe>
</template>

<script>
export default {
  name: 'IframeOrVideo',
  props: {
    src: {
      type: String,
      default: ''
    },
    controls: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVideo () {
      return this.src && !!this.src.match(/(.mp3)|(.mp4)|(.webm)|(.ogg)|(.mov)|(.avi)$/i)
    }
  }
}
</script>
